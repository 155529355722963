<template>
  <div
    class="multi-select-box"
    :class="{ selected: isFocus }"
    @click="clickFieldHandler"
  >
    <v-select
      v-model="carNumber.country"
      :items="filteredCountries"
      item-text="simple-name"
      no-data-text="Не знайдено"
      attach=".multi-select-box"
      hide-details
      return-object
      :menu-props="{ closeOnClick: true }"
    >
      <template slot="selection" slot-scope="data">
        <img :src="`/images/flags/${data.item.iso}.svg`" alt="" />
      </template>
      <template v-slot:prepend-item>
        <v-text-field v-model="searchCountry" hide-details></v-text-field>
      </template>
      <template slot="item" slot-scope="data">
        <img :src="`/images/flags/${data.item.iso}.svg`" alt="" />
        <span>{{ data.item['simple-name'] }}</span>
      </template>
    </v-select>
    <TextField
      id="car-number"
      v-model.trim="carNumber.value"
      type="text"
      name="car-number"
      required
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <InputError v-if="error" :error-txt="errorTxt" is-show-icon />
  </div>
</template>
<script>
import TextField from '@/elements/Inputs/TextField.vue';
import InputError from '@/elements/Errors/InputError.vue';

export default {
  components: {
    TextField,
    InputError,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    isFocus: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchCountry: '',
    };
  },
  computed: {
    carNumber: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    filteredCountries() {
      if (!this.searchCountry) {
        return this.items;
      }

      return this.items.filter((item) =>
        item['simple-name']
          .toLowerCase()
          .includes(this.searchCountry.toLowerCase())
      );
    },
  },
  methods: {
    clickFieldHandler() {
      this.$emit('focus');
    },
  },
};
</script>

<style lang="sass">
.multi-select-box
  display: flex
  align-items: center
  background-color: var(--primary-color-white)
  border-radius: var(--primary-border-radius)
  border: 1px solid var(--primary-color-dark-grey-1)
  width: 100%
  height: 64px
  position: relative

.multi-select-box.selected
  border: 2px solid var(--primary-color-dark-blue)

.multi-select-box .v-select
  flex: 0 0 75px
  height: 100%
  margin: 0
  padding: 0
  display: flex
  align-items: center

.multi-select-box .v-select__selections
  padding: 15px 0 15px 16px

.multi-select-box .v-select .v-input__slot
  height: auto
  padding: 0
  margin: 0!important
  border: 0

.multi-select-box .v-select .v-input__slot:before,
.multi-select-box .v-select .v-input__slot:after
  display: none

.multi-select-box .v-select .v-input__icon:before
  background-image: url('/images/flags-select-caret.svg')
  position: absolute
  content: ''
  top: 50%
  right: 16px
  width: 10px
  height: 6px
  transform: translateY(-50%)
  transition: var(--transition-speed)

.multi-select-box .v-select--is-menu-active .v-input__icon:before
  transform: translateY(-50%) rotate(180deg)

.multi-select-box .input-box .input
  border-radius: 0
  border-left: 1px solid var(--primary-color-dark-grey-1)
  border-right: none!important
  border-top: none!important
  border-bottom: none!important
  height: 48px
  padding: 0 16px
  font-size: 18px
  font-weight: 600
  color: var(--primary-color-dark-blue)
  text-transform: uppercase

.multi-select-box .v-menu__content
  width: 100%
  max-width: 100%
  top: 64px!important
  box-shadow: 0px 40px 50px 4px rgba(38, 55, 76, 0.1)
  border: 1px solid var(--primary-color-dark-grey-1)
  border-radius: var(--primary-border-radius)!important

.multi-select-box .v-menu__content::-webkit-scrollbar
    width: 5px
    background-color: #EEF1F4
    border-radius: 5px
    height: 200px

.multi-select-box .v-menu__content::-webkit-scrollbar-thumb
    background: var(--primary-color-dark-grey-1)
    border-radius: 5px

.multi-select-box .v-menu__content .v-text-field
  padding: 0
  margin: 0

.multi-select-box .v-menu__content .v-text-field .v-input__slot
  padding: 0 16px

.multi-select-box .v-menu__content .v-text-field .v-input__slot:before
  background: url('/images/search.svg') no-repeat
  border-top: 0!important
  width: 24px!important
  height: 24px
  bottom: auto!important
  left: 16px!important

.multi-select-box .v-menu__content .v-text-field.v-input--is-focused .v-input__slot:before
  background: url('/images/search-focus.svg') no-repeat

.multi-select-box .v-menu__content .v-text-field .v-input__slot:after
  transform: initial!important
  border: 0!important
  bottom: 0!important
  left: 16px!important
  height: 1px
  width: calc(100% - 32px)!important
  background-color: var(--primary-color-dark-grey-1)!important

.multi-select-box .v-menu__content .v-text-field .v-input__slot input
  font-weight: 500
  font-size: 18px
  color: var(--secondary-color-dark-blue)
  margin: 20px 0
  padding: 0 16px 0 40px

.multi-select-box .v-list
  padding: 0

.multi-select-box .v-list-item
  min-height: 100%!important

.multi-select-box .v-list-item__content
  padding: 2px 0

.multi-select-box .v-select__selections,
.multi-select-box .v-list-item
  img
    width: 24px
    height: 16px

.multi-select-box .v-list .theme--light.v-list-item
  background-color: var(--primary-color-white)
  font-size: 18px
  padding: 16px

.multi-select-box .v-list-item.v-list-item--active::before
  opacity: 1!important
  background-color: transparent
  background-image: url('/images/check.svg')
  position: absolute
  content: ''
  top: 50%
  left: auto
  right: 16px
  width: 18px
  height: 14px
  transform: translateY(-50%)

.multi-select-box .theme--light.v-list-item--active::before
  opacity: 0

.multi-select-box .v-list .theme--light.v-list-item:not(.v-list-item--highlighted):not(.v-list-item--disabled):hover
  color: var(--secondary-color-dark-blue)!important

.multi-select-box .v-list-item__title
  font-size: 18px
  font-weight: 500
  text-align: left

.multi-select-box .v-list-item span
  font-weight: 500
  margin-left: 16px
</style>
